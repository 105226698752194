<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-radio-group
          v-model="vm.isWheelChairAccessRequired"
          label="Does the participant require wheelchair access into a venue?"
          v-validate="'required'"
          data-vv-as="Participant require wheelchair access into a venue"
          name="isWheelChairAccessRequired"
          :error-messages="errors.collect('isWheelChairAccessRequired')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAffectedByNoise"
          label="Does loud or other noises affect the participant?"
          v-validate="'required'"
          data-vv-as="Noise affect the participant"
          name="isAffectedByNoise"
          :error-messages="errors.collect('isAffectedByNoise')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" v-if="vm.isAffectedByNoise == true">
        <v-textarea
          filled
          v-model="vm.noisesThatAffect"
          label="Please describe the noises that affect the participant"
          v-validate="'required'"
          data-vv-as="Noises that affect the participant"
          name="noisesThatAffect"
          :error-messages="errors.collect('noisesThatAffect')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAffectByCrowds"
          label="Do crowds affect the participant?"
          v-validate="'required'"
          data-vv-as="Crowds affect the participant"
          name="isAffectByCrowds"
          :error-messages="errors.collect('isAffectByCrowds')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" v-if="vm.isAffectByCrowds == true">
        <v-textarea
          filled
          v-model="vm.crowdsThatAffect"
          label="Please provide examples"
          v-validate="'required'"
          data-vv-as="Kinds of Crowds that affect the participant"
          name="crowdsThatAffect"
          :error-messages="errors.collect('crowdsThatAffect')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAffectedByAnimals"
          label="Do animals affect the participant?"
          v-validate="'required'"
          data-vv-as="Animals affect the participant"
          name="isAffectedByAnimals"
          :error-messages="errors.collect('isAffectedByAnimals')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" v-if="vm.isAffectedByAnimals == true">
        <v-textarea
          filled
          v-model="vm.AnimalsThatAffect"
          label="Kinds of animals that affect the participant"
          v-validate="'required'"
          data-vv-as="Kinds of animals that affect the participant"
          name="AnimalsThatAffect"
          :error-messages="errors.collect('AnimalsThatAffect')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAffectedByAnythingElse"
          label="Does anything else affect the participant?"
          v-validate="'required'"
          data-vv-as="Anything else affect the participant"
          name="isAffectedByAnythingElse"
          :error-messages="errors.collect('isAffectedByAnythingElse')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" v-if="vm.isAffectedByAnythingElse == true">
        <v-textarea
          filled
          v-model="vm.anythingElseThatAffects"
          label="List anything else that affects the participant"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="vm.isRequiredOutingSupport"
          label="Does the participant require 1:1 staff support on outings?"
          v-validate="'required'"
          data-vv-as="Participant require 1:1 staff support on outings"
          name="isRequiredOutingSupport"
          :error-messages="errors.collect('isRequiredOutingSupport')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAtRiskOfFallsOrTrips"
          label="Is the participant at risk of falls or trips?"
          v-validate="'required'"
          data-vv-as="Participant at risk of falls or trips"
          name="isAtRiskOfFallsOrTrips"
          :error-messages="errors.collect('isAtRiskOfFallsOrTrips')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAbleToAttendOutingsWithoutSupport"
          label="Can the participant attend community access outings without support staff?"
          v-validate="'required'"
          data-vv-as="Participant attend community access outings without support staff"
          name="isAbleToAttendOutingsWithoutSupport"
          :error-messages="errors.collect('isAbleToAttendOutingsWithoutSupport')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" v-if="vm.isAbleToAttendOutingsWithoutSupport == true">
        <v-textarea
          filled
          v-model="vm.listActivities"
          label="If Yes, please list activites (e.g. walk to shops)"
          v-validate="'required'"
          data-vv-as="List Activites"
          name="listActivities"
          :error-messages="errors.collect('listActivities')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isResponsibleForOwnMoneyOnOutings"
          label="Is the participant able to look after their own money on outings?"
          v-validate="'required'"
          data-vv-as="Participant able to look after their own money on outings"
          name="isResponsibleForOwnMoneyOnOutings"
          :error-messages="errors.collect('isResponsibleForOwnMoneyOnOutings')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAbleToBeInvolvedInWaterSports"
          label="Can the participant be involved in outings involving water sports (e.g. swimming, sailing etc)?"
          v-validate="'required'"
          data-vv-as="Participant be involved in outings involving water sports"
          name="isAbleToBeInvolvedInWaterSports"
          :error-messages="errors.collect('isAbleToBeInvolvedInWaterSports')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" v-if="vm.isAbleToBeInvolvedInWaterSports == true">
        <v-textarea
          filled
          v-model="vm.listWaterSportActivities"
          label="If Yes, any issues to be aware of?"
          v-validate="'required'"
          data-vv-as="Issues to be aware of"
          name="listWaterSportActivities"
          :error-messages="errors.collect('listWaterSportActivities')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAwareOfRoadSafety"
          label="Is the participant aware of road safety"
          v-validate="'required'"
          data-vv-as="Aware of road safety"
          name="isAwareOfRoadSafety"
          :error-messages="errors.collect('isAwareOfRoadSafety')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAbleToTravelIndependently"
          label="Can the participant travel independently?"
          v-validate="'required'"
          data-vv-as="Participant travel independently"
          name="isAbleToTravelIndependently"
          :error-messages="errors.collect('isAbleToTravelIndependently')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAwareOfStrangerDanger"
          label="Is the participant aware of stranger danger?"
          v-validate="'required'"
          data-vv-as="Participant aware of stranger danger"
          name="isAwareOfStrangerDanger"
          :error-messages="errors.collect('isAwareOfStrangerDanger')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "safety-and-outings-form",
  data: () => ({
    vm: {},
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.safetyAndOutings == undefined
          ? {}
          : this.$store.getters.currentCase.safetyAndOutings;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.safetyAndOutings = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

